<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="users-page">
    <b-card class="" title="Users List">
      <b-card-text>This page shows all users who can login and <strong>manage</strong> the MDM solution. You can manage users and their passwords directly from this page.</b-card-text>
    </b-card>
    <b-card no-body class="mb-0">
      <div class="row p-2" v-if="$can('Create', 'User')">
        <b-col cols="6">
          <b-button @click="$bvModal.show('modal-add-user')" variant="primary">Create User</b-button>
        </b-col>
        <b-col cols="6">
          <b-input-group>
            <b-form-input
              @keydown.enter="getMoreUsers(1)"
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search for a user..."
            />
            <b-input-group-append>
              <b-button @click="getMoreUsers(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          :items="users"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(roles)="data">
            <p class="d-inline-block mb-0" style="margin-right: 2px" v-for="(role, index) in data.item.roles"
               :key="role.id">
              {{ getRoleWord(data.item.roles, role.name, index) | capEachWord }}
            </p>
          </template>
          <template #cell(last_seen)="data">
            <span v-if="data.item.last_seen">
              {{ data.item.last_seen | formatDateTime }}
            </span>
            <span v-else>
              Never
            </span>
          </template>
          <template #cell(name)="data">
            <router-link :to="{ name: 'user-view', params: { uid: data.item.uid } }"
                         class="font-weight-bold d-block text-nowrap text-underline">
              {{ data.item.name }}
            </router-link>
          </template>
          <template #cell(actions)="data">
            <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'User')"
                                      @click="showUserModal(data.item, 'modal-update-user')">
                Update User
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'User')"
                                      @click="showUserModal(data.item, 'modal-password-user')">
                Change password
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'User')"
                                      @click="showUserModal(data.item, 'modal-delete-user')">
                Delete user
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreUsers" :total-rows="total_count" :per-page="filters.page_size"
                          v-model="currentPage" class="my-0"/>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-add-user" title="Add new user" hide-footer>
      <add-user @refresh="refreshUsers()" @close="closeModals()"></add-user>
    </b-modal>

    <b-modal id="modal-update-user" title="Update user" hide-footer>
      <update-user v-if="selectedUser" :user-existing="selectedUser" @refresh="refreshUsers()"
                   @close="closeModals()"></update-user>
    </b-modal>

    <b-modal id="modal-password-user" title="Change user password" hide-footer>
      <change-password-user v-if="selectedUser" :user="selectedUser" @refresh="refreshUsers()"
                            @close="closeModals()"></change-password-user>
    </b-modal>

    <b-modal id="modal-delete-user" title="Delete user" hide-footer>
      <delete-modal
        v-if="selectedUser"
        @close="closeModals()" @delete="deleteUser(selectedUser)"
        :subtitle="selectedUser.name"
        title="Are you sure you wish to delete user"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import UserService from '../../services/UserService'
import AddUser from './sections/AddUser.vue'
import UpdateUser from './sections/UpdateUser.vue'
import DeleteModal from '../../components/modals/DeleteModal.vue'
import ChangePasswordUser from './sections/ChangePasswordUser.vue'

export default {
  name: 'Users',
  components: {
    AddUser,
    DeleteModal,
    UpdateUser,
    ChangePasswordUser,
  },
  props: {},
  data() {
    return {
      selectedUser: null,
      headers: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'last_seen',
          label: 'Last Seen',
          sortable: true,
        },
        {
          key: 'roles',
          label: 'Roles',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      users: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    refreshUsers() {
      this.closeModals()
      this.getUsers()
    },
    closeModals() {
      this.$bvModal.hide('modal-add-user')
      this.$bvModal.hide('modal-delete-user')
      this.$bvModal.hide('modal-update-user')
      this.$bvModal.hide('modal-password-user')
    },
    getMoreUsers(val) {
      this.filters.page = val - 1 // api index starts at 0, bootstrap starts at 1 lol
      this.getUsers()
    },
    getUsers() {
      UserService.getUsers(this.filters)
        .then(res => {
          this.users = res.data.data
          this.filters.page = res.data.meta.page
          this.filters.page_size = res.data.meta.page_size
          this.total_count = res.data.meta.total_count
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get users, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    setSelectedUser(user) {
      this.selectedUser = user
    },
    showUserModal(user, modalName) {
      this.setSelectedUser(user)
      this.$bvModal.show(modalName)
    },
    deleteUser(user) {
      UserService.deleteUser(user.uid)
        .then(() => {
          this.$toast.success(`Deleted user ${user.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.refreshUsers()
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not delete user, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    getRoleWord(array, word, index) {
      return index < array.length - 1 ? `${word},` : word
    },
  },
}
</script>
